import React from "react"
import { graphql, StaticQuery } from "gatsby"
import RenderRichText from "../../utils/contentful-rich-text"
import { Gmap } from "./gmap"

export const stableQuery = graphql`
  query StableQuery {
    contentfulStable {
      body {
        raw
      }
    }
  }
`;

export default function Stable() {
  return (
    <StaticQuery
      query={stableQuery}
      render={data => {
        return (
          <>
            <section className="px-4 mt-6 text-justify md:mx-auto md:max-w-7xl">
              {RenderRichText(data.contentfulStable.body)}
            </section>
            <Gmap />
          </>
        )
      }}
    />
  )
}

